import consumer from "./consumer";

consumer.subscriptions.create({ channel: "BackgroundJobChannel" }, {
  received(data) {
    var id = "background_job_" + data.id;
    var node = document.getElementById(id);

    if (!node) {
      var table = document.getElementById('background-jobs');

      if (!table) {
        return;
      }

      var node = table.insertRow(0);
      node.id = id;

      node.insertCell(-1).classList.add('created_at');
      node.insertCell(-1).classList.add('updated_at');
      node.insertCell(-1).classList.add('description');
      node.insertCell(-1).classList.add('status');
      node.insertCell(-1).classList.add('output');

      var delete_row = node.insertCell(-1);
      delete_row.classList.add('delete');
      delete_row.classList.add('text-right');
    }

    node.querySelector('.created_at').innerText = data.created;
    node.querySelector('.updated_at').innerText = data.updated;
    node.querySelector('.description').innerText = data.description;
    node.querySelector('.status').innerText = data.status;
    node.querySelector('.output').innerHTML = data.output;
    node.querySelector('.delete').innerHTML = data.delete;

  }
});
