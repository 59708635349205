// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";
import Dropzone from "dropzone";

Dropzone.options.formFolderUpload = {
  dictDefaultMessage: 'Sleep bestanden of mappen om te uploaden',
  parallelUploads: 1,
  maxFilesize: 1024,
  init: function () {
    this.on('sending', function(file, _xhr, data) {
      if (file.fullPath) {
        data.append("fullPath", file.fullPath);
      }
    });

    this.on('success', function(_file, data) {
      if (document.querySelector('.flash')) {
        document.querySelector('.flash').remove();
      }
      document.getElementById('digital-object-files').innerHTML = data;
    });
  }
};

document.addEventListener("turbolinks:load", function() {
  try {
    Dropzone.discover();
  } catch(e) { }
});
